.component__dashboardnavbar {       
    border: 1px solid grey;
    min-height: 80px;
    z-index: 15;
    background-color: white;

    position: absolute;
    left: 0; right: 0; top: 0;
    
    
    
}


.dashboardnavbar__home h2 {
    color: black;
    margin: 0;
}

/* NAVBAR Buttons*/
.dashboardnavbar__navigate {
    flex-wrap: wrap;
   
}
.dashboardnavbar__navigation-buttons {    
    height: 100%;
    position: relative;
    
}
.dashboardnavbar__navigation-buttons button {
    border: 1px solid grey;
    background-color: rgba(255, 0, 0, 0);
    height: 100%;
    min-width: 100px;
    cursor: pointer;
    color:black;
    transition: all 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.dashboardnavbar-large {   
    width: 100%;
    
}
