.component__footer {
    position: relative;
    height: 100vh;
    width: 100vw;
    /* background-color: rgb(71, 142, 213); */
    /* background-color: rgba(221, 205, 85, 0.664); */
    background-color: rgba(170, 140, 53, 0.664);
    align-items: flex-end;
    justify-content: space-between;
    color: white !important ;
    
}
.component__footer img{
      
    
    top: 0; left: 0; right: 0; bottom: 0;
    position: absolute;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    transform: translate3d(0,0,0);  
    

}
.component__footer h4,
.component__footer p,
.component__footer h2{
    
    color: white !important ;
}


.footer__info {
    gap: 5rem;
}
.footer__info-left p {
    margin: 0.1rem 0;
}
.footer__info-right {

}
.footer__info-right div{
    align-items: center;
    font-weight: 900;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.footer__info-right div:hover{
    transform: translateX(2px);
 
}

.footer__icons #react-icon-instagram:hover,
.footer__icons #react-icon-facebook:hover{
    width: 50px; height: 50px;
    cursor: pointer;
    color:rgba(255, 255, 255, 0.341);
}

.footer__icons #react-icon-instagram, 
.footer__icons #react-icon-facebook{
    width: 50px; height: 50px;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    
}   

.footer__copyright {
    align-self: center;
    
}



@media screen and (max-width: 650px){
    .component__footer h2 {
        font-size:18px;
    }
    .component__footer p {
        font-size:15px;
    }
    .footer__info {
        flex-direction: column;
        gap: 0;
    }
  }
  

  
@media screen and (max-height: 700px){   
    .component__footer h2 {
        font-size: 20px;
    }
    .component__footer p {
        font-size: 12px;
    }
   
}
@media screen and (max-height: 500px){   
    .component__footer {
        
        height: 100%;
        
        
    }
    .component__footer img{
        height: 100%;
    }

}

