.container__servicedashboard {
    font-family: var(--font-main);
    min-height: 100vh;
    width: 100vw;
    
    padding: 5rem 0;
}
.container__servicedashboard h1{
    color: black;
}
.servicedashboard__update-btn {
    margin-top: 2rem;
    gap: 0.2rem;
}
.servicedashboard__intro p{
    margin: 0;
    color: black;
    max-width: 80vw;
}
.servicedasboard__item {
    border: 1px solid grey;
    margin-bottom: 1rem;
    width: 600px;
}
.servicedasboard__item-arrow {
    width: 100%;
    justify-content: space-evenly;
    margin: 0.5rem 0;
}
.servicedasboard__item textarea{
    height: 100px;
    width: 500px;
    margin-bottom: 0.8rem;
}
.image__uploads {
    margin: 1rem 0;
}
.image__uploads img{
    width: 80px; height: 80px;
    object-fit: cover;
}



@media screen and (max-width: 700px){
    .servicedasboard__item {
        width: 80vw;
    }
    .servicedasboard__item textarea{
        width: 70vw;
    } 
    .image__uploads {
        width: 70vw;
        flex-wrap: wrap;
    }
    .image__uploads input{
        width: 150px;        
    }
    .container__servicedashboard {
        padding-top: 10rem;
    }
    .container__servicedashboard h1{
        font-size:30px;
    }

}

