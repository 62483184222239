.container__eventdashboard {
    min-height: 100vh;
    width: 100vw;
    justify-content: flex-start;
    padding: 5rem 0;
}
.container__eventdashboard h1,
.container__eventdashboard p{
        color: black;
}
.eventdashboard__upload textarea{
    height: 100px;
    width: 80vw;
    
}
.eventdashboard__list {
    gap: 0.5rem;
}
.eventdashboard__list__item {
    width: 80vw;
    border: 1px solid grey;
}
.eventdashboard__list__item button{
    width: 20%;

    
}
.eventdashboard__list__item-images {
    flex-wrap: wrap;
    gap: 0.2rem;
}
.eventdashboard__list__item-img {
    width: 100px;
    height: 100px;
    margin-bottom: 2rem;
}
.eventdashboard__list__item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



@media screen and (max-width: 800px){
    .container__eventdashboard {
        padding-top: 10rem;
    }
    .container__eventdashboard h1{
        font-size: 25px;
    }
}