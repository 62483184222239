.container__lunch {
    /* min-height: 100vh; */
    min-height: 100vh;
    width:100vw;            
}
.container__lunch p,
.container__lunch h2{
    text-align: center;
}

.lunch {
    height: 100%; width: 100vw;
    min-height: 34vh;
    background-color: var(--color-main);
    margin-top: 66vh;    
}
.lunch-other,
.lunch-err {
    height: 100%; max-width: 80vw;
    min-height: 34vh;
    background-color: var(--color-main);
 
}
.lunch h2:nth-child(2){
    align-items: center;
}
.lunch h2 #react-icon-arrow{
    cursor: pointer;
}




.lunch__day {
 
    max-width: 80%;
 
}
.lunch-item {
    margin-bottom: 1.5rem;
    max-width: 80%;
    flex-wrap: wrap;
}
.lunch-item p{
    font-size: 15px;
    margin: 0;
}
.lunch-item p:first-child{  
    font-weight: 900;
}
.lunch-item span{
    color: white;
    margin: 0 0.5rem;

 
}





.lunch-img {  
    top: 0; left: 0; right: 0; bottom: 0;
    position: absolute;
    object-fit: cover;
    width: 100vw;
    height: 66vh;
    z-index: -1;
    transform: translate3d(0,0,0);  
}
.lunch-img-small {  
   
    object-fit: cover;
    width: 100vw;
    height: 80vh;
  
    transform: translate3d(0,0,0);  
}
.lunch-img-full {  
    top: 0; left: 0; right: 0; bottom: 0;
    position: absolute;
    object-fit: cover;
    width: 100vw;
    min-height: 100vh;
    z-index: -1;
    transform: translate3d(0,0,0);  
}
.lunch-img-sticky {  
    top: 0; left: 0; right: 0; bottom: 0;
    position: fixed;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    z-index: -2;
    transform: translate3d(0,0,0);  
}

.lunch-img-regular {  
    
    object-fit: cover;
    width: 100vw;
    height: 50vh;
    
    transform: translate3d(0,0,0);  
}





@media screen and (max-width: 800px){
    
}

@media screen and (max-width: 350px){   
    
}
@media screen and (max-width: 550px){   
 
}

