.container__forgotpasswordpage {
    min-height: 100vh;
    background-color: white;
    color: black;

}
.container__forgotpasswordpage button,
.container__forgotpasswordpage p,
.container__forgotpasswordpage h3,
.container__forgotpasswordpage label {
    
    color: black;
}