.container__gallery2 {
    min-height: 100vh; width: 100vw;
    background-color: var(--color-main);
    padding-top:5rem;
    padding-bottom: 2rem;
}

.container__gallery2 h2,
.container__gallery2 p{
    text-align: center;       
    max-width: 90vw;
}


.gallery2__card {
    height: 80vh;
    width: 50vw;
    background-color: var(--color-hover);
    border-radius: 2rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.gallery2__card-line {
    position: relative;
    width: 100%;
    height: 5% !important;
    background-color: rgb(255, 255, 255);
}
.gallery2__card-line-circle {
    position: absolute;
    width: 80px; height: 80px;
    border-radius: 50%;
    background-color: rgb(251, 251, 251);
    
    
}



.gallery2__card-info {
    height: 40%;
    width: 90%;
}
.gallery2__card-info h2,
.gallery2__card-info p{
    text-align: center;
   
}

.container__gallery2 .swiper-img{
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
    object-fit: cover;
    height: 55% !important;
    width: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    
  
    
}
  
.container__gallery2 .swiper {
    max-width: 100vw;
    height: 100%;
    cursor: pointer; 
    
    
}
/*   .swiper-wrapper {
    
    display: flex;
    justify-content: center;
    align-items: center;
  
  } */
.container__gallery2 .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 650px){
    .gallery2__card {
        height: 65vh;
        width: 80vw;       
    }
    .gallery2__card-info h2{
        font-size: 15px;
    }
    .gallery2__card-info p{
        font-size: 12px;
    }
    .container__gallery2 h2{
        font-size: 20px;
    }
    .container__gallery2 p{
        font-size: 15px;
    }
    .gallery2__card-line-circle {

        width: 60px; height: 60px;
       
        
        
    }
}


@media screen and (max-height: 650px){
    .gallery2__card {
        height: 90vh;
        width: 80vw;       
    }
    .gallery2__card-info h2{
        font-size: 15px;
        margin-bottom: 0;
    }
    .gallery2__card-info p{
        font-size: 12px;
        margin: 0;
    }
    .container__gallery2 h2{
        font-size: 12px;
    }
    .container__gallery2 p{
        font-size: 12px;
    }
    .gallery2__card-line-circle {
        width: 35px; height: 35px;                       
    }
    .gallery2__card-line-circle img {
        width: 30px; height: 30px;                       
    }
}
  

@media screen and (max-width: 650px){
   /*  .container__gallery2 .swiper-img{
      height: 50%;
    } */
}
  