.container__introtext {
    min-height: 100vh;
    /* height: 100vh; */
    width:100vw;
    
    background-color: var(--color-main);
    align-items: flex-end;
    padding: 5rem 0;
    color: var(--color-text-white);
    border-bottom: 1px solid var(--color-main);
   
}
.introtext__80vw {
    width: 80vw;
}

.introtext__title {
    padding-right: 2rem ;
    
}
.introtext__title h1{
    margin: 0;
    color:var(--color-text-white);    
}

.introtext__img {
    justify-content: flex-end;
    
}
.introtext__img img{
    object-fit: cover;
    max-height: 700px;
    width: 80vw;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}


.introtext__link {
    align-items: center;
   
}
.introtext__link div{
    align-items: center;
    cursor: pointer;   
    transition: transform 0.2s ease-in-out;
}
.introtext__link div:hover  {
    transform: translateX(2px);
}


.introtext__link #react-icon-arrow {

}


@media screen and (max-width: 800px){
    .introtext__title h1 {
        font-size: 50px;
    }
    

}

@media screen and (max-width: 500px){   
    .introtext__title h1 {
        font-size: 35px;
    }
    .introtext__80vw {
        width: 100vw;
    }
    .introtext__title {
        padding: 0 2rem ;
        
    }
    .introtext__link {
        padding-left: 2rem ;       
    }
    .introtext__img {
        justify-content: center;
        
    }
    .container__introtext {
        padding: 3rem 0;
    }
}
@media screen and (max-width: 350px){   
    .introtext__title h1 {
        font-size: 25px;
    }
}

