
.container__gallery {
  background-color: var(--color-main);
  min-height: 100vh;
  width: 100vw;  
  border: 1px solid var(--color-bg);
  
}
.container__gallery p{
  text-align: center;
  
}
.container__gallery { 
  color: white;
  font-size: 30px;
}




.swiper-img{
  
  object-fit: cover;
  height: 700px !important;
  width: 80vw;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  max-height: 80vh;

  
}

.swiper {
  max-width: 100vw;
  height: 100%;
  cursor: pointer; 
  
  
}
/* .swiper-wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

} */
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 650px){
  .swiper-img{
    height: 50%;
  }
}
