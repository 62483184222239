.container__header {
    /* min-height: 100vh; */
    height: 100vh;
    width:100vw;            
}
.header__title {
    justify-content: flex-start;
    
}

.header__title h1{
    margin: 0;
   /*  color:var(--color-text-white);     */
    color:var(--color-hover);    
    font-family: var(--font-header);
    text-align: center;
}
.header__title img{
   margin-top: 2rem;
}
#react-main-icon {
    color: white;
    width: 30px; height: 30px;
}



@media screen and (max-width: 760px){
    .header__title h1 {
        font-size: 50px;
    }
    .container__header .klippan__logo__big {
        width: 150px; height: 150px;
    }
}

@media screen and (max-width: 440px){   
    .header__title h1 {
        font-size: 30px;
    }
    .header__title p {
        font-size: 15px;
    }
    .container__header .klippan__logo__big {
        width: 100px; height: 100px;
    }
}




@media screen and (max-height: 550px){   
    .header__title h1 {
        font-size: 30px;
    }
    .header__title p {
        font-size: 15px;
    }
    .container__header .klippan__logo__big {
        width: 100px; height: 100px;
    }
}
@media screen and (max-height: 350px){   
    .header__title h1 {
        font-size: 25px;
    }
    .header__title p {
        font-size: 12px;
    }
    .container__header .klippan__logo__big {
        width: 50px; height: 50px;
    }
}

