.container__dashboard {
    min-height: 100vh;
    background-color: white;
    position: relative;
    z-index: 2;
    font-family: var(--font-main);
}

.container__dashboard h1,
.container__dashboard form h3,
.container__dashboard form label,
.container__dashboard form button,
.container__dashboard form p{
    color: black;
}


.forgotpassword {
    position: absolute;    
    z-index: 5;
    top: 0;left: 0;right: 0; bottom: 0;
    background-color: white;
}

.forgotpassword form{
    
}
