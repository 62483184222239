.container__event {
    /* min-height: 100vh; */
    min-height: 100vh;
    width:100vw;            
}
.container__event p,
.container__event h2{
    text-align: center;
}

.event {
    height: 100%; width: 100vw;
    min-height: 64vh; /* ! CHANGE BACK TO 34vh */
    background-color: var(--color-main);
    margin-top: 66vh;    
    padding-bottom: 2rem;
}



.event h2,
.event p {
    max-width: 80vw;
}



.event-img {  
    top: 0; left: 0; right: 0; bottom: 0;
    position: absolute;
    object-fit: cover;
    width: 100vw;
    height: 66vh;
    z-index: -1;
    transform: translate3d(0,0,0);  
}




/* Dropdown */


.event__dropdown ul {
    margin: 0;
    padding: 0;
    z-index: 2;
}  
.event__dropdown ul li {
    list-style: none;     
    display: inline-block;
    position: relative;
    text-decoration: none;     
}
  
.event__dropdown button {
    background-color: var(--color-hover);
    border: none;
    color: white;
    cursor: pointer;
    min-width:250px;
}
  
.event__dropdown li button:hover {
    color: #3ca0e7;
}        
  
.event__dropdown ul li ul {
    visibility: hidden;
    opacity: 0;
    position: absolute;

    display: none;
    background: var(--color-hover);
    padding: 0.5rem 0;
    transition: all 0.5s ease;
}
  
.event__dropdown ul li:hover > ul
    /* ul li ul:hover */ {
    visibility: visible;
    opacity: 1;
    display: flex;
    justify-content: flex-start;
   /*  min-width: 150px; */
    text-align: left;  

}
  
.event__dropdown ul li ul li {
    clear: both;
    width: 100%;
    text-align: left;    
  
    border-style: none;
    margin: 0.4rem 0;
}
  
.event__dropdown ul li ul li button:hover {
    padding-left: 10px;
    border-left: 2px solid #3ca0e7;
    transition: all 0.3s ease;
}
          
.event__dropdown ul li ul li button { transition: all 0.5s ease; }
  

.event-line {
    width: 90vw; height: 2px;
    background-color: rgba(195, 195, 195, 0.317);
    border-radius: 5rem;
    margin: 5rem 0;
}


.event__show {
   /*  margin-top: 5rem; */
}  



.event .sqrbtn-second {
    margin: 2rem 0;
}


  
@media screen and (max-width: 650px){
    .event__show .swiper-img{
        height: 300px;
    }
}
  
@media screen and (max-width: 500px){ 
    .event h2 {
        font-size: 20px;
    }
    .event p {
        font-size: 15px;
    }
}

@media screen and (max-width: 350px){   
    
}
@media screen and (max-width: 550px){   
 
}

