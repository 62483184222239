.container__abortbookingpage {
    background-color: var(--color-main);
    width: 100vw;
    height: 100vh;
}
.abortbookingpage__message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 80vw; height: 300px;
 
    padding: 2rem 2rem;
}
.abortbookingpage__message button {
    margin-top: 2rem;
    color: white;
}
.abortbookingpage__message #react-icon {
    width: 70px; height: 70px;
    color: var(--maintextcolor);
    color: rgb(39, 221, 39);
}