.container__menu {
    /* min-height: 100vh; */
    /* min-height: 100vh; */
    width:100vw;            
}

.menu__salad {
    height: 100%; width: 100vw;
    min-height: 34vh;
    background-color: var(--color-main);
    margin-top: 66vh;
    
}
.menu__pizza{
    height: 100%; width: 100vw;
    background-color: var(--color-main);
  
}
.menu__alacarte {
    height: 100%; width: 100vw;
    background-color: var(--color-main);
    margin-top: 75vh;
}




.menu-item {
    margin-bottom: 1.5rem;
    max-width: 80%;
    flex-wrap: wrap;
}
.menu-item p{
    font-size: 15px;
    margin: 0;
}
.menu-item span{
    color: white;
    margin: 0 0.5rem;

 
}





.menu-img {  
    top: 0; left: 0; right: 0; bottom: 0;
    position: absolute;
    object-fit: cover;
    width: 100vw;
    height: 66vh;
    z-index: -1;
    transform: translate3d(0,0,0);  
}
.menu-img-small {  
   
    object-fit: cover;
    width: 100vw;
    height: 80vh;
  
    transform: translate3d(0,0,0);  
}
.menu-img-full {  
    top: 0; left: 0; right: 0; bottom: 0;
    position: absolute;
    object-fit: cover;
    width: 100vw;
    min-height: 100vh;
    z-index: -1;
    transform: translate3d(0,0,0);  
}
.menu-img-sticky {  
    top: 0; left: 0; right: 0; bottom: 0;
    position: fixed;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    z-index: -2;
    transform: translate3d(0,0,0);  
}

.menu-img-regular {  
    
    object-fit: cover;
    width: 100vw;
    height: 50vh;
    
    transform: translate3d(0,0,0);  
}




  
.menu__drinks {
    position: relative;
    min-height: 100vh;
    width: 100vw;
}
.menu__drinks-container {
    background-color: rgba(74, 74, 74, 0.824);
    width: 90vw;
    color: white;
    
    margin: 1rem 0;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.menu__drinks-container-columns {
    justify-content: center;
    width: 100%;
}
.menu__drinks-container-column {
    width: 25%;
}
.drink-item {   
    margin-bottom: 1.5rem;
}
.drink-item p{
    margin: 0;
}
.drink-item p:first-child{
    color: var(--color-hover);
}

@media screen and (max-width: 800px){
    .menu__drinks-container-columns {
        flex-direction: column;
    }
}

@media screen and (max-width: 350px){   
    
}
@media screen and (max-width: 550px){   
 
}

