.component__smicons {
    position: fixed;
    
    bottom: 3rem; right:3rem;
    gap:1rem;
    z-index: 1;
}

.component__smicons #react-icon-instagram,
.component__smicons #react-icon-facebook,
.component__smicons #react-icon-map{
    width: 25px; height: 25px;
    color: var(--color-hover);
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    
}   


.component__smicons #react-icon-instagram:hover,
.component__smicons #react-icon-facebook:hover,
.component__smicons #react-icon-map:hover{            
    color: white
}   




@media screen and (max-width: 650px){
    .component__smicons {
        display: none;
    }
}