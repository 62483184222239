.container__gallerydashboard {
    min-height: 100vh;
    width: 100vw;
    justify-content: flex-start;
    padding: 5rem 0;
    
    
}
.gallerydashboard__images-img {
    width: 100px;
    height: 100px;
    margin-bottom: 2rem;
}
.gallerydashboard__images-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


@media screen and (max-width: 800px){
    .container__gallerydashboard {
        padding-top: 10rem;
    }
    .container__gallerydashboard h1{
        font-size: 25px;
    }
}