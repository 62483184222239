.container__snsdashboard {
    min-height: 100vh;
    width: 100vw;      
}

.container__snsdashboard p{
    color: black;
}


.snsdashboard__number-item {
    border: 1px solid grey;
    padding: 1rem;
}