.container__lunchdashboard {
    min-height: 100vh;
    width: 100vw;
    
    padding: 5rem 0;
}
.container__lunchdashboard h3,
.container__lunchdashboard h1,
.container__lunchdashboard p{
    color: black;
}
.container__lunchdashboard textarea{
    width: 80vw;
    height: 80px;
}

.lunchdashboard__category {
    gap: 1rem;
}



.lunchdashboard__drinks__category,
.lunchdashboard__drinks,
.lunchdashboard__category {
    border: 1px solid grey;
    margin-bottom: 5rem;
    padding: 1rem;
}

.lunchdashboard__drinks__category-item:nth-child(odd),
.lunchdashboard__category-item:nth-child(odd) { 
    background-color: rgba(24, 192, 83, 0.156);
    padding: 1rem;
}







@media screen and (max-width: 800px){
    .container__lunchdashboard {
        padding-top: 10rem;
    }
    .container__lunchdashboard h1{
        font-size: 25px;
    }
}