.component__navbar {
    
    position: fixed;
    left: 0; right: 0; top: 0;
    height: 80px;    
    justify-content: space-around;  
    z-index: 2;
}


/* ICON (left) */
.navbar__icon {
    cursor: pointer;
}
.navbar__icon h1{
    font-family: var(--icon-font);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.navbar__icon:hover h1{
    color: var(--color-hover);
}



/* Navigation buttons (middle) */
.navbar__navigation {    
    height: 100%;
    gap: 0.5rem;
    
}

/* NAVBAR Buttons*/
.navbar__navigation-buttons {    
    height: 100%;
    position: relative;
    
}
.navbar__navigation-buttons button {
    border: none;
    background-color: rgba(255, 0, 0, 0);
    height: 100%;
    min-width: 100%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.navbar-line{
    position: absolute;
    bottom: 15px; 
    width: 0%;
    height: 2px;
    opacity: 0;
    border-radius: 50%;
    background-color: var(--color-hover);    
    transition: all 0.2s ease-in-out;
}
.navbar__navigation-buttons:hover .navbar-line{    
    width: 95%;    
    opacity: 1;
}
.navbar__navigation-buttons:hover button{    
    color:  var(--color-hover);
}





































/* mobile menu*/
.navbar-small__icon {
    position: fixed;
    top: 30px; right: 20px;
    display: none;
    color: rgb(255, 255, 255);
    height: 40px; width: 40px;
    z-index: 2;
    cursor: pointer;
}
.navbar-small {        
    position: fixed;
    display: none;
    top: 0;    
    right: 0;    
    width: 100vw;    
    height: 100vh;
    background-color: var(--color-main);    
    transition: all 0.5s ease-in-out;  
    gap: 1rem;          
}

.navbar-small .no-border{
    border: none;
}
.navbar-small button{
    cursor: pointer;
    font-size: 45px;    
    background-color: rgba(0, 0, 0, 0);
    color: white;    
}
.navbar-small .square-btn{
    padding: 0.2rem 0.4rem 0.2rem 0.4rem;
    border: 3px solid white;
}





/* HAMBURGER MENU*/
.navbar-small__icon span
{
  display: flex;
  width: 29px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

.navbar-small__icon span:first-child
{
    
    /* transform: rotate(45deg) translate(-3px,-1px) */
}

.navbar-small__icon span:nth-last-child(2)
{    
/*   transform-origin: 0% 100%; */
  
   
}
.navbar-small__icon span:nth-last-child(1)
{    
    transform-origin: 0% 100%;
    /* transform: rotate(-45deg) translate(0,1px) */
   
}























@media screen and (max-width: 920px){
      
    .navbar__navigation-sqrbtn button,
    .navbar__navigation-buttons button {
        min-width: 80px;
        font-size: 15px;
    }
    .component__navbar {         
       
    }
    
}


@media screen and (max-width: 700px){
  
    .navbar__navigation-buttons button {
        min-width: 45px;
        font-size: 10px;
    }
    .navbar__navigation-sqrbtn button {
        width: 45px;
        font-size: 10px;
    }
    .navbar__icon h1 {
        font-size: 30px;
    }
  
}

@media screen and (max-width: 540px){
    .navbar__cartitems {
        width: 100vw;
    }
}
@media screen and (max-width: 500px){
    .navbar__navigation,
    .navbar__booking{
        display: none;
    }  
    .navbar-small {
        display: flex;
    }
    .navbar-small__icon {
        display: block;
    }
    .component__navbar {
        justify-content: center;
    }
}


@media screen and (max-width: 450px){
   .navbar-small button {
        font-size: 25px;
   }
}