.container__settingsdashboard {
    min-height: 100vh;
    width: 100vw;      
}

.container__settingsdashboard p{
    color: black;
}


