@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap');




@import url('https://fonts.googleapis.com/css2?family=Cinzel&display=swap');
:root {
  /* --color-main: rgb(119, 161, 142); */
  --color-main: rgb(95, 143, 118);
  --color-main-darker:rgb(95,143,118,0.8);
  --color-bg-main: rgb(51, 51, 51);
  --color-bg-second: rgb(69, 69, 69);
  --color-second: #EFC050;
  --color-second-Opac: #EFC050;
  --color-text-white:white;
  --color-text-whiteOpac:rgb(188, 188, 188);
  --font-main: 'Titillium Web', sans-serif;
  --icon-font: 'Mr Dafoe', cursive;   /*'Audiowide', cursive;*/
  --color-hover: #92d1b0;
  --color-hover-second: #EFC050;


  --test1: 'Caveat', cursive;
  --test2: 'Indie Flower', cursive;
  --test3:'Satisfy', cursive;
  --test4: 'Amatic SC', cursive;
  --font-header:'Cinzel', serif;
}

html, body {overflow-x: hidden !important;width: 100vw !important;}
a {
  text-decoration: none !important;
  color: inherit;
}
body {
  padding: 0;
  margin: 0;
  background-color: black;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
 
  
}
p {
  white-space: pre-line;
}
.grecaptcha-badge { 
  visibility: hidden;
}


/* FONTS */
.h1__font-large {
  font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 90px;
}
.h1__font-medium {
  font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 50px;
}
.h1__font-small {
  font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 40px;
}
.h2__font-large {
  font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 35px;
}
.h2__font-medium {
  font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 30px;
}
.h2__font-small {
  font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 25px;
}
.h3__font-large {
  font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 20px;
  font-weight: 100;
}
.h4__font-small {
  font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 15px;
}
.h4__font-medium {
  font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 18px;
}
.p__font-large {

}
.p__font-medium {
  font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 20px;
  
}
.p__font-small {
font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 18px;
}
.p__font-xsmall {
  font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 12px;
}





.flex__center-r {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex__center-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-c {
  display: flex;
  flex-direction: column;
}
.flex {
  display: flex;
}
.container__80vw {
  width: 80vw;
  height: 100%;
}






.header-img {
  /* filter: brightness(0.5); */
  filter: blur(5px);
  top: 0; left: 0; right: 0; bottom: 0;
  position: absolute;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  transform: translate3d(0,0,0);  
}







/* Square Button*/
.navbar__navigation-sqrbtn{
  height: 100%;
}

.navbar__navigation-sqrbtn button{
  background: none;
  border: none;
  cursor: pointer;
 
 
  padding: 0.2rem 0.6rem 0.2rem 0.6rem;
  letter-spacing: 0.05rem;
}
.navbar__navigation-sqrbtn .square-btn {
  box-shadow: inset 0 0 0 2px #f5f5f5;
  color: #f7f7f7;
  transition: color 0.25s 0.0833333333s;
  position: relative;
}
.navbar__navigation-sqrbtn .square-btn::before, .navbar__navigation-sqrbtn .square-btn::after {
  border: 0 solid transparent;
  box-sizing: border-box;
  content: '';
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
}
.navbar__navigation-sqrbtn .square-btn::before {
  border-bottom-width: 3px;
  border-left-width: 3px;
}
.navbar__navigation-sqrbtn .square-btn::after {
  border-top-width: 3px;
  border-right-width: 3px;
}
.navbar__navigation-sqrbtn .square-btn:hover {
  color: var(--color-hover);
}
.navbar__navigation-sqrbtn .square-btn:hover::before, .navbar__navigation-sqrbtn .square-btn:hover::after {
  border-color: var(--color-hover);
  transition: border-color 0s, width 0.25s, height 0.25s;
  width: 100%;
  height: 100%;
}
.navbar__navigation-sqrbtn .square-btn:hover::before {
  transition-delay: 0s, 0s, 0.25s;
}
.navbar__navigation-sqrbtn .square-btn:hover::after {
  transition-delay: 0s, 0.25s, 0s;
}





/* Square Button second color*/
.sqrbtn-second{
  height: 100%;
}

.sqrbtn-second button{
  background: none;
  border: none;
  cursor: pointer;
 
 
  padding: 0.2rem 0.6rem 0.2rem 0.6rem;
  letter-spacing: 0.05rem;
}
.sqrbtn-second .square-btn {
  box-shadow: inset 0 0 0 2px #f5f5f5;
  color: #f7f7f7;
  transition: color 0.25s 0.0833333333s;
  position: relative;
}
.sqrbtn-second .square-btn::before, .sqrbtn-second .square-btn::after {
  border: 0 solid transparent;
  box-sizing: border-box;
  content: '';
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
}
.sqrbtn-second .square-btn::before {
  border-bottom-width: 3px;
  border-left-width: 3px;
}
.sqrbtn-second .square-btn::after {
  border-top-width: 3px;
  border-right-width: 3px;
}
.sqrbtn-second .square-btn:hover {
  color: var(--color-hover-second);
}
.sqrbtn-second .square-btn:hover::before, .sqrbtn-second .square-btn:hover::after {
  border-color: var(--color-hover-second);
  transition: border-color 0s, width 0.25s, height 0.25s;
  width: 100%;
  height: 100%;
}
.sqrbtn-second .square-btn:hover::before {
  transition-delay: 0s, 0s, 0.25s;
}
.sqrbtn-second .square-btn:hover::after {
  transition-delay: 0s, 0.25s, 0s;
}





/* Oval button*/
.btn-oval {
  cursor: pointer;
  background-color: var(--color-hover);
  width: 200px; height: 50px;
  border-radius: 2rem;
  border: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: all 0.1s linear;
}
.btn-oval:active {        
  background-color: var(--color-dark);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}







/* CHECKBOX */

.checkbox-wrapper-19 {
  box-sizing: border-box;
  --background-color: #00000000;
  --checkbox-height: 25px;
}

@-moz-keyframes dothabottomcheck-19 {
  0% {
    height: 0;
  }
  100% {
    height: calc(var(--checkbox-height) / 2);
  }
}

@-webkit-keyframes dothabottomcheck-19 {
  0% {
    height: 0;
  }
  100% {
    height: calc(var(--checkbox-height) / 2);
  }
}

@keyframes dothabottomcheck-19 {
  0% {
    height: 0;
  }
  100% {
    height: calc(var(--checkbox-height) / 2);
  }
}

@keyframes dothatopcheck-19 {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: calc(var(--checkbox-height) * 1.2);
  }
}

@-webkit-keyframes dothatopcheck-19 {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: calc(var(--checkbox-height) * 1.2);
  }
}

@-moz-keyframes dothatopcheck-19 {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: calc(var(--checkbox-height) * 1.2);
  }
}

.checkbox-wrapper-19 input[type=checkbox] {
  display: none;
}

.checkbox-wrapper-19 .check-box {
  height: var(--checkbox-height);
  width: var(--checkbox-height);
  background-color: transparent;
  border: calc(var(--checkbox-height) * .1) solid #a5a5a5;
  border-radius: 5px;
  position: relative;
  display: inline-block;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-transition: border-color ease 0.2s;
  -o-transition: border-color ease 0.2s;
  -webkit-transition: border-color ease 0.2s;
  transition: border-color ease 0.2s;
  cursor: pointer;
}
.checkbox-wrapper-19 .check-box::before,
.checkbox-wrapper-19 .check-box::after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  height: 0;
  width: calc(var(--checkbox-height) * .2);
  background-color: #34b93d;
  display: inline-block;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  border-radius: 5px;
  content: " ";
  -webkit-transition: opacity ease 0.5;
  -moz-transition: opacity ease 0.5;
  transition: opacity ease 0.5;
}
.checkbox-wrapper-19 .check-box::before {
  top: calc(var(--checkbox-height) * .72);
  left: calc(var(--checkbox-height) * .41);
  box-shadow: 0 0 0 calc(var(--checkbox-height) * .05) var(--background-color);
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.checkbox-wrapper-19 .check-box::after {
  top: calc(var(--checkbox-height) * .37);
  left: calc(var(--checkbox-height) * .05);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.checkbox-wrapper-19 input[type=checkbox]:checked + .check-box,
.checkbox-wrapper-19 .check-box.checked {
  border-color: #34b93d;
}
.checkbox-wrapper-19 input[type=checkbox]:checked + .check-box::after,
.checkbox-wrapper-19 .check-box.checked::after {
  height: calc(var(--checkbox-height) / 2);
  -moz-animation: dothabottomcheck-19 0.2s ease 0s forwards;
  -o-animation: dothabottomcheck-19 0.2s ease 0s forwards;
  -webkit-animation: dothabottomcheck-19 0.2s ease 0s forwards;
  animation: dothabottomcheck-19 0.2s ease 0s forwards;
}
.checkbox-wrapper-19 input[type=checkbox]:checked + .check-box::before,
.checkbox-wrapper-19 .check-box.checked::before {
  height: calc(var(--checkbox-height) * 1.2);
  -moz-animation: dothatopcheck-19 0.4s ease 0s forwards;
  -o-animation: dothatopcheck-19 0.4s ease 0s forwards;
  -webkit-animation: dothatopcheck-19 0.4s ease 0s forwards;
  animation: dothatopcheck-19 0.4s ease 0s forwards;
}











  /* Checkobox small */  
  .checkbox-wrapper-8 .tgl {
    display: none;
  }
  .checkbox-wrapper-8 .tgl,
  .checkbox-wrapper-8 .tgl:after,
  .checkbox-wrapper-8 .tgl:before,
  .checkbox-wrapper-8 .tgl *,
  .checkbox-wrapper-8 .tgl *:after,
  .checkbox-wrapper-8 .tgl *:before,
  .checkbox-wrapper-8 .tgl + .tgl-btn {
    box-sizing: border-box;
  }
  .checkbox-wrapper-8 .tgl::-moz-selection,
  .checkbox-wrapper-8 .tgl:after::-moz-selection,
  .checkbox-wrapper-8 .tgl:before::-moz-selection,
  .checkbox-wrapper-8 .tgl *::-moz-selection,
  .checkbox-wrapper-8 .tgl *:after::-moz-selection,
  .checkbox-wrapper-8 .tgl *:before::-moz-selection,
  .checkbox-wrapper-8 .tgl + .tgl-btn::-moz-selection,
  .checkbox-wrapper-8 .tgl::selection,
  .checkbox-wrapper-8 .tgl:after::selection,
  .checkbox-wrapper-8 .tgl:before::selection,
  .checkbox-wrapper-8 .tgl *::selection,
  .checkbox-wrapper-8 .tgl *:after::selection,
  .checkbox-wrapper-8 .tgl *:before::selection,
  .checkbox-wrapper-8 .tgl + .tgl-btn::selection {
    background: none;
  }
  .checkbox-wrapper-8 .tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 4em;
    height: 2em;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .checkbox-wrapper-8 .tgl + .tgl-btn:after,
  .checkbox-wrapper-8 .tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
  }
  .checkbox-wrapper-8 .tgl + .tgl-btn:after {
    left: 0;
  }
  .checkbox-wrapper-8 .tgl + .tgl-btn:before {
    display: none;
  }
  .checkbox-wrapper-8 .tgl:checked + .tgl-btn:after {
    left: 50%;
  }

  .checkbox-wrapper-8 .tgl-skewed + .tgl-btn {
    overflow: hidden;
    transform: skew(-10deg);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transition: all 0.2s ease;
    font-family: sans-serif;
    background: #888;
  }
  .checkbox-wrapper-8 .tgl-skewed + .tgl-btn:after,
  .checkbox-wrapper-8 .tgl-skewed + .tgl-btn:before {
    transform: skew(10deg);
    display: inline-block;
    transition: all 0.2s ease;
    width: 100%;
    text-align: center;
    position: absolute;
    line-height: 2em;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  }
  .checkbox-wrapper-8 .tgl-skewed + .tgl-btn:after {
    left: 100%;
    content: attr(data-tg-on);
  }
  .checkbox-wrapper-8 .tgl-skewed + .tgl-btn:before {
    left: 0;
    content: attr(data-tg-off);
  }
  .checkbox-wrapper-8 .tgl-skewed + .tgl-btn:active {
    background: #888;
  }
  .checkbox-wrapper-8 .tgl-skewed + .tgl-btn:active:before {
    left: -10%;
  }
  .checkbox-wrapper-8 .tgl-skewed:checked + .tgl-btn {
    background: #86d993;
  }
  .checkbox-wrapper-8 .tgl-skewed:checked + .tgl-btn:before {
    left: -100%;
  }
  .checkbox-wrapper-8 .tgl-skewed:checked + .tgl-btn:after {
    left: 0;
  }
  .checkbox-wrapper-8 .tgl-skewed:checked + .tgl-btn:active:after {
    left: 10%;
  }






  /* LOGO */

  .klippan__logo {    
    width: 50px; height: 50px;
  }
  .klippan__logo__big {
    width: 200px; height: 200px;
  }