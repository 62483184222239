/* LOADING SCREEN */ 
.loadingscreen {
    position: fixed;
    backdrop-filter: blur(10px);
    background-color: rgba(41, 41, 41, 0.866);
  
    width: 100vw; height: 100vh;
    top: 0; left: 0; right : 0; bottom : 0;
    
    z-index: 15;
    
  }
  .loadingscreen h5 {
    margin-top: 2rem;
    color: var(--color-main);
    
  }
  .loadingscreen h1 {  
    font-size: 45px;
    color: var(--color-main);
    font-family: var(--font-header);
    
  }
  
.loadingscreen__container {
position: relative;
}

.loadingscreen__container .main-icon {

}
.loadingscreen__container .main-icon h1{
    margin: 0;
    text-align: center;
}
  
  .loadingscreen__orbit {
    position: absolute;
    top: -100px;
    left: -66px;
    width: 400px;
    height: 400px;
    
    border-radius: 100%;
    -webkit-animation: spin-right 2s ease-in-out infinite;
    animation: spin-right 2s ease-in-out infinite;
    
  }
  .loadingscreen__circle {
    position: absolute;
   /*  top: 62px; */
    left: 50%;
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: var(--color-main);
    
  }
  @keyframes spin-right {
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  