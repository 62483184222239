.container__infodashboard {
    min-height: 100vh;
    width: 100vw;
    
    padding: 5rem 0;
}

.infodasboard__dbinformation {
    width: 80vw;
}
.container__infodashboard textarea{
    width: 80vw;
    height: 100px;
}

.dbinformation {
    margin-bottom: 2rem;
}
.dbinformation__item{
    margin-bottom: 1rem;
    
    
}
.dbinformation__item-time input{    
    width: 40px;
}
.dbinformation__item-time p{
    margin: 0;
    width: 25%;
}


@media screen and (max-width: 800px){
    .container__infodashboard {
        padding-top: 10rem;
    }
    .container__infodashboard h1{
        font-size: 25px;
    }
}