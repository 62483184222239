.container__contact {
    min-height: 100vh;
    
    width:100vw;
    
    background-color: var(--color-main);
    align-items: flex-end;

    color: var(--color-text-white);
   
   
}
.contact__80vw {
    width: 75vw; padding-right: 5vw;
    
}

.contact__title {
    padding-right: 2rem ;
    
}
.contact__title h1{
    margin: 0;
    color:var(--color-text-white);    
}

.contact__title div {
    align-items: center;    
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}
.contact__title div:hover  {
    transform: translateX(2px);
}



.contact__form {
    width: 100%;            
}
.contact__form input{
    background-color: rgba(255, 255, 255, 0);

    font-size: 25px;
    width: 50%;

    border-bottom: 2px solid rgb(188, 63, 63) ;
    border-top: none;
    border-left: none;
    border-right: none;
    color: white;
    transition: border-color 0.5s ease-out;
    
}
.contact__form textarea{
    background-color: rgba(255, 255, 255, 0);

    font-size: 25px;
    width: 45%;
    height: 200px;

    border-bottom: 2px solid rgba(255, 255, 255, 0.642) ;
    border-top: none;
    border-left: none;
    border-right: none;
    color: white;
}



.contact__form textarea::placeholder,
.contact__form input::placeholder{
    color: rgba(255, 255, 255, 0.58);
    font-size: 20px;
}



.contact__form textarea:focus,
.contact__form input:focus {
    outline: none;
}




.contact__form-name {
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;
}
.contact__form-name input{
    width: 45%;
}




.contact__form-email {
    justify-content: space-between;
}
.contact__form-email input{
    width: 45%;
    margin-bottom: 1rem;
}




.contact__form-phone {
    margin-bottom: 1rem;
}

.contact__form-phone input{
    width: 45%;
    border-color:rgba(255, 255, 255, 0.642);
}




.contact__form-msg textarea {
    border: 2px solid rgba(255, 255, 255, 0.669);
}

@media screen and (max-width: 800px){
    .contact__title h1 {
        font-size: 50px;
    }
    

}

@media screen and (max-width: 500px){   
    .contact__title h1 {
        font-size: 35px;
    }
    .contact__80vw {
        width: 100vw;
        padding: 0;
    }
    .contact__title {
        padding: 0 2rem ;
        
    }    
    .container__contact {
        padding: 3rem 0;
    }
    .contact__form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .contact__form textarea,
    .contact__form input{
        width: 100%;
    }
    .contact__form-msg,
    .contact__form-phone,
    .contact__form-email,
    .contact__form-name {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 85vw;
    }
    .contact__form-name input:first-child{
        margin-bottom: 1rem;
    }
}
@media screen and (max-width: 350px){   
    .contact__title h1 {
        font-size: 25px;
    }
}

