.container__bookingdashboard {
    min-height: 100vh;
    width: 100vw;
    
    padding: 5rem 0;
    align-items: center;
    
}
.container__bookingdashboard p{
    color: black;
}
.bookingdashboard__bookingitem {
    justify-content: flex-start;    
    gap: 1rem;
    width: 90vw;
    /* border-bottom: 1px solid rgba(128, 128, 128, 0.406); */
    cursor: pointer;
}



.bookingdashboard__bookingitem-expanded {
    cursor: pointer;
    width: 90vw;
    
    word-wrap: break-word;
}
.bookingdashboard__bookingitem-expanded p{
    margin: 0;
   
    max-width: 90vw;
    
}



.bookingdashboard__bookingitem-expanded:nth-child(odd),
.bookingdashboard__bookingitem:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.118);
}


/* DELETE BOOKING*/
.bookingdashboard__delete {
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;

    z-index: 15;        
}
.bookingdashboard__delete-blur {
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    background-color: rgba(53, 53, 53, 0.431);
    backdrop-filter: blur(5px);
    z-index: 15;        
    cursor: pointer;
}
.bookingdashboard__delete-container {    
    margin: auto;
    width: 50vw;
    height: 40vh;
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    background-color: white;
    z-index: 15;   
}
.bookingdashboard__delete p{
    
}

@media screen and (max-width: 800px){
    .container__bookingdashboard {
        padding-top: 10rem;
    }
    .container__bookingdashboard h1{
        font-size: 25px;
    }
}