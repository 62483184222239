.container__booking {
    min-height: 100vh;
    width: 100vw;
    background-color: var(--color-main);
    padding-bottom: 5rem;
}


.booking {
    width: 50vw; height: 700px;
    justify-content: flex-start;
   
}
.booking-navbar {
    width: 100%;
}
.booking span{
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s linear;
}
.booking span:hover {
    transform: translateX(-2px);
}
.booking #react-icon-back {
    color: white;    
    width: 20px; height: 20px;
}

.booking-navbar-upper {
    justify-content: space-between;

}
.booking-navbar-upper p{
    width: 20%;
    text-align: center;
    cursor: pointer;
}




/* TYPE */
.booking__type {
    gap: 1rem;
}





/* PEOPLE */
.booking__people {
    flex-wrap: wrap;
    gap: 0.5rem;
}
.booking__people div {
    border: 1px solid white;
    border-radius: 50%;
    width: 40px; height: 40px;
    cursor: pointer;    
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: all 0.1s linear;
    
}
.booking__people div:active {
    background-color: var(--color-hover);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.booking__people div:hover { 
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}


/* Time */
.booking__time {
    flex-wrap: wrap;
    gap: 0.5rem;
}
.booking__time div {
    border: 1px solid white;
    border-radius: 50%;
    width: 40px; height: 40px;
    cursor: pointer;    
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: all 0.1s linear;
    
}
.booking__time div:active {
    background-color: var(--color-hover);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.booking__time div:hover { 
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}


/* form */
.booking__form {

}
.booking__form form div input{
    border-bottom: 2px solid white !important;
    font-size: 15px;
}
.booking__form form div textarea{
    
    font-size: 15px;
}
.booking__form form div input::placeholder,
.booking__form form div textarea::placeholder{    
    font-size: 15px;
}
.booking__form .navbar__navigation-sqrbtn button{
    font-size: 25px !important;
    
}





/** Calender */
.react-calendar {
    background-color: var(--color-main) !important;
    border: none !important;
    font-family: var(--font-main) !important;
    
}
/* Month year mars 2024 */
.react-calendar span{    
    font-family: var(--font-main) !important;
    color: white;
}
/* Prev/ Next year button */
.react-calendar .react-calendar__navigation__prev2-button, 
.react-calendar .react-calendar__navigation__next2-button {
    display: none !important;
}
/* Prev/ Next month button */
.react-calendar .react-calendar__navigation__prev-button, 
.react-calendar .react-calendar__navigation__next-button {
    border: 1px solid white;
    border-radius: 50%;
    color: white;
}
/* Disabled Prev/Next month button */
.react-calendar__navigation button:disabled {
    background-color: var(--color-main);
    border: 1px solid rgba(255, 255, 255, 0.219);
    color: rgba(255, 255, 255, 0.219);
}
/* Hovering the Disabled Prev/Next month button */
.react-calendar__navigation button:disabled:hover {
    background-color: var(--color-main) !important;
    
}
/* Hovering the month year button in the middle */
.react-calendar .react-calendar__navigation__label:hover {
    background-color: var(--color-main) !important;
}

/* Week day names */
.react-calendar .react-calendar__month-view__weekdays div abbr{
    text-decoration: none;
    color: white;
}

/* Every button */
.react-calendar button {
    transition: all 0.2s linear;
}
/* Hover for every button */
.react-calendar button:hover {
    background-color: rgb(120, 179, 148) !important;
    
}

/* All days */
.react-calendar__month-view__days {
   /*  gap: 0.5rem; */
}
/* Each day div element */
.react-calendar .react-calendar__tile  {
  /*   background-color: var(--color-hover); */
    border-radius: 50%;
  /*   width: 50px !important; height: 50px !important; */
    aspect-ratio : 1 / 1;
}

.react-calendar .react-calendar__tile abbr {
   /*  color: white; */
}
/* When a day is disabled */
.react-calendar__tile:disabled {
    background-color: var(--color-main) !important;
    color: rgba(255, 255, 255, 0.143) !important;
}
/* When a day is disabled and hovered */
.react-calendar__tile:disabled:hover {
    background-color:  var(--color-main) !important;
}
/* The current day today */
.react-calendar__tile--now {
    background: var(--color-hover);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
/* When a day is clicked / selected */
.react-calendar__tile--active {
    background: rgb(66, 178, 66) !important;
    color: white;
}






@media screen and (max-width: 500px){
    .booking {
        width: 80vw;
    }
    .booking__type {
      
        flex-direction: column;
    }
    
    .react-calendar {
        /* width: 80vw; */
        width: 250px;
        /* height: 240px;  */
    }
    .react-calendar__month-view__days {
        /* width: 250px;
        height: 100%; */
    }
    .react-calendar__tile{
        width: 40px !important;
        height: 40px !important;
    }

   
    .booking-navbar-upper p{
        font-size: 8px;
    }
  

}
@media screen and (max-height: 800px){
    .booking {
        padding-top: 6rem;
    }   
}
@media screen and (max-width: 300px){
   
   

}